export const formatTel = (tel, stripDash = false) => {
  let formatTel = tel;
  if (tel[0] !== '0') {
    formatTel = `0${tel}`;
  }

  if (stripDash) {
    formatTel = formatTel.split('-')[0];
  }

  return formatTel;
};

export const youtubeURL = (id) => {
  return `https://www.youtube.com/embed/${id}/?autoplay=1&rel=0&modestbranding=1`;
};

export const removeSpan = (text) => {
  return text.replace(/<\/?span[^>]*>/g, '');
};

export const removeTags = (text) => {
  return text.replace(/<\/?span[^>]*>|<br\s?\/?>/g, '');
};

export const removeP = (text) => {
  return text.replace(/<\/?p[^>]*>/g, '');
};

export const removeHTMLTag = (text = '') => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const formatPrice = (val, unit = true) => {
  const num: number = +val;
  return (
    (unit ? 'USD ' : '') +
    num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const removeHash = (text: string) => {
  return text.replace('#', '');
};

export const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const removeAsterisk = (text: string) => {
  return text.replace(/[*]/g, '');
};

export const formatNumber = (val, decimal = 0) => {
  return Number(val).toFixed(decimal);
};

export const cleanUrl = (val) => {
  return val.replace('//', '/');
};

export const removeDuplicateDot = (value) => {
  while (value.split('.').length > 2) {
    const lastIndex = value.lastIndexOf('.');
    value = `${value.substring(0, lastIndex)}${value.substring(lastIndex + 1)}`;
  }
  return value;
};

export const limitFloat = (value, decimalNumber) => {
  if (
    value.split('.').length === 2 &&
    value.split('.')[1].length > decimalNumber
  ) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, decimalNumber);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};
