import { ImageProps } from '@/components/shared/media/image/Image.props';

interface IData {
  price: number;
  label: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

export const V_CROSS_DATA: IData = {
  price: 49500,
  label: 'V-CROSS 4X4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
    alt: 'V-CROSS 4X4',
    width: 1600,
    height: 1200,
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0c1c1ce99855437/cambodia-v-cross-brochure-2025.pdf',
};

export const D_MAX_4X4_DATA: IData = {
  price: 39900,
  label: 'D-MAX 4X4',
  url: '/isuzu-d-max-4x4',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7bf7d43216692c3c/679201b4e05cbf6d245631c0/d-max-4x4-menu.png',
    alt: 'D-MAX 4X4',
    width: 1600,
    height: 1200,
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7e9fb037c3199000/cambodia-d-max-4x4-brochure-2025.pdf',
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 42500,
  label: 'HI-LANDER',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
    alt: 'HI-LANDER',
    width: 1600,
    height: 1200,
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0f57beb952cddf4d/cambodia-4-doors-brochure-2025.pdf',
};

export const SPARK_DATA: IData = {
  price: 32800,
  label: 'SPARK',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
    alt: 'SPARK',
    width: 1600,
    height: 1200,
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6a56b5660d57a05/cambodia-spark-brochure-2025.pdf',
};

export const MUX_NEXT_PEAK_DATA: IData = {
  price: 59900,
  label: 'MU-X',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt455fcdb5b1712026/679202c0b042f562d020a3cd/mu-x-menu.png',
    alt: 'MU-X The Next Peak',
    width: 1600,
    height: 1200,
  },
  // NOTE: permanent link
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb6e0318054636938/cambodia-mu-x-brochure-2025.pdf',
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `តម្លៃចាប់ពី USD ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const D_MAX_4X4_MENU: IMenu = {
  label: D_MAX_4X4_DATA.label,
  description: `តម្លៃចាប់ពី USD ${D_MAX_4X4_DATA.price.toLocaleString()}`,
  url: D_MAX_4X4_DATA.url,
  image: D_MAX_4X4_DATA.image,
  brochure: D_MAX_4X4_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `តម្លៃចាប់ពី USD ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `តម្លៃចាប់ពី USD ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_NEXT_PEAK_MENU: IMenu = {
  label: MUX_NEXT_PEAK_DATA.label,
  description: `តម្លៃចាប់ពី USD ${MUX_NEXT_PEAK_DATA.price.toLocaleString()}`,
  url: MUX_NEXT_PEAK_DATA.url,
  image: MUX_NEXT_PEAK_DATA.image,
  brochure: MUX_NEXT_PEAK_DATA.brochure,
  // FOR SHOW A BADGE
};
