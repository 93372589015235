import type { BodyScrollOptions } from 'body-scroll-lock';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSwitchTransition, useTransition } from 'transition-hook';

import { Button } from '@/components/shared/element/buttons/button';
import { ButtonIcon } from '@/components/shared/element/buttons/button-icon';
import Hamburger from '@/components/shared/element/hamburger';
import { Icon } from '@/components/shared/element/icons';
import { Link } from '@/components/shared/element/link';
import { LogoCommonLCV, LogoLCV } from '@/components/shared/element/logo/lcv';
import NewBadge from '@/components/shared/element/new-badge';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Image } from '@/components/shared/media/image';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { BUTTON_LCV_REGISTER } from '@/contents/lcv/link';
import { IMenu } from '@/types/layout/menu';

import {
  CarContentItem,
  CarContentList,
  CarMenuWrap,
  Close,
  Header,
  LogoWrap,
  MainNav,
  MenuItem,
  MenuList,
  MenuMobileClose,
  MenuMobileTop,
  MenuMobileTopInner,
  MenuPanel,
  MenuPanelOverlay,
  Overlay,
  PrimaryNav,
  SubMenu,
  SubMenuBack,
  SubMenuBackIcon,
  SubMenuCar,
  SubMenuCarLCV,
} from './styled';

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

interface ICarMenuView extends IMenu {
  currentSubmenu?: number;
}
interface IProps {
  menuBuyingTool?: IMenu[];
  menuMain?: IMenu[];
  menuModel?: IMenu[];
  menuRegister?: IMenu;
  isTransparent?: boolean;
  showMenuPanel?: boolean;
}

const CarMenuLayoutLCV = (props: ICarMenuView) => {
  return (
    <Grid rowGap={{ '@lg': 4 }}>
      {!!props.label && (
        <Text size="h6" font="bold" variant="text-gray">
          {props.label}
        </Text>
      )}

      {!!(props.submenu && props.submenu.length > 0) && (
        <SubMenuCarLCV>
          {props.submenu.map((sub, i) => (
            <React.Fragment key={i}>
              {!!(sub.submenu && sub.submenu.length > 0) && (
                <SubMenuCar size="2">
                  {sub.submenu.map((j, i) => (
                    <Link
                      className="sub-menu-car-link"
                      href={j.url}
                      key={i}
                      tracking={{
                        dataTrack: 'primary-nav',
                        dataTrackText: j.label,
                        dataTrackUrl: j.url,
                      }}
                    >
                      <Box>
                        <Text
                          size={{
                            '@initial': 'base',
                            '@lg': 'h6',
                          }}
                          font="bold"
                          variant="text-black"
                        >
                          {j.label}
                        </Text>
                        <Text size="2xs" variant="text-gray-dark">
                          {j.description}
                        </Text>
                      </Box>
                      {!!j.image && (
                        <Box className="car-image">
                          {!!j.new && (
                            <NewBadge
                              css={{
                                top: -14,
                                '@lg': {
                                  top: -8,
                                },
                              }}
                            />
                          )}

                          <Image
                            priority
                            src={j.image.src}
                            alt={j.image.alt}
                            width={j.image.width}
                            height={j.image.height}
                            layout="responsive"
                            aspectRatio="4by3"
                            sources={[
                              {
                                srcset: j.image.src,
                                media: { minWidth: 768 },
                                options: { width: 256, crop: '4:3' },
                              },
                              {
                                srcset: j.image.src,
                                media: { maxWidth: 767 },
                                options: { width: 185, crop: '4:3' },
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Link>
                  ))}
                </SubMenuCar>
              )}
            </React.Fragment>
          ))}
        </SubMenuCarLCV>
      )}
    </Grid>
  );
};

const View = ({ isTransparent = false, menuModel = [], ...props }: IProps) => {
  const windowSize = useWindowSize();
  const [submenu, setSubmenu] = useState(-1);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleBuyingTools, setToggleBuyingTools] = useState(false);

  const [togglePanel, setTogglePanel] = useState(false);

  const menuToggleBuyingTools = useRef(null);
  const menuTogglePanel = useRef(null);
  const menuToggle = useRef(null);
  const subMenuToggle = useRef(null);
  const refArray = useRef([]);

  const router = useRouter();

  const PANEL_TRANS = useTransition(togglePanel, 600);
  const PANEL_OVERLAY_TRANS = useTransition(togglePanel, 1000);

  const transition = useSwitchTransition(submenu, 600, 'default');
  const OVERLAY_TRANS = useTransition(submenu !== -1, 1000);

  const [toggleTransparent, setToggleTransparent] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      onCloseSubMenu();
      setToggleMenu(false);
      setToggleBuyingTools(false);
      setTogglePanel(false);

      clearAllBodyScrollLocks();
      document.querySelector('header').classList.remove('is-product-menu-open');
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (windowSize.width >= 1024) {
      if (toggleBuyingTools) {
        setToggleBuyingTools(false);
      }
      if (toggleMenu) {
        setToggleMenu(false);
        onCloseSubMenu();
      }
    } else {
      if (!toggleMenu && submenu !== -1) {
        onCloseSubMenu();
      }
      if (togglePanel) {
        setTogglePanel(false);
      }
    }
  }, [toggleMenu, submenu, toggleBuyingTools, togglePanel, windowSize.width]);

  useEffect(() => {
    if (toggleMenu) {
      setToggleBuyingTools(false);
      document.documentElement.style.setProperty(
        '--menu-height',
        `${window.innerHeight}px`
      );
    } else {
      onCloseSubMenu();
    }
  }, [toggleMenu]);

  useEffect(() => {
    if (submenu !== -1) {
      setTogglePanel(false);
    }
  }, [submenu]);

  useEffect(() => {
    if (togglePanel) {
      onCloseSubMenu();
    }
  }, [togglePanel]);

  // NOTE: check lock scroll
  useEffect(() => {
    if (
      toggleBuyingTools || // mobile: buying tools
      toggleMenu || // mobile: menu
      (!toggleMenu && submenu !== -1) || // desktop: menu
      togglePanel // desktop: panel
    ) {
      // mobile: buying tools
      if (toggleBuyingTools) {
        if (menuToggleBuyingTools.current) {
          disableBodyScroll(menuToggleBuyingTools.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // mobile: menu
      if (toggleMenu) {
        clearAllBodyScrollLocks();
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';

        if (submenu === -1) {
          if (menuToggle.current) {
            disableBodyScroll(menuToggle.current, options);
          }
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        } else {
          if (refArray.current[submenu]) {
            disableBodyScroll(refArray.current[submenu], options);
          }
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        }
        return;
      }

      // desktop: submenu
      if (!toggleMenu && submenu !== -1) {
        if (subMenuToggle.current) {
          disableBodyScroll(subMenuToggle.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // desktop: panel
      if (togglePanel) {
        if (menuTogglePanel.current) {
          disableBodyScroll(menuTogglePanel.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }
    } else {
      clearAllBodyScrollLocks();
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [toggleBuyingTools, togglePanel, submenu, toggleMenu]);

  const onClickMenu = (index: number) => {
    if (submenu === index) {
      return onCloseSubMenu();
    }
    setToggleTransparent(true);
    return setSubmenu(index);
  };

  const onCloseSubMenu = () => {
    setToggleTransparent(false);
    setSubmenu(-1);
  };

  const isTransparentNavigation = useMemo(() => {
    return isTransparent && !toggleTransparent;
  }, [isTransparent, toggleTransparent]);

  return (
    <Header isTransparent={!!isTransparent}>
      <PrimaryNav
        className={submenu !== -1 && 'is-subnav-open'}
        isTransparent={isTransparentNavigation}
      >
        <Container>
          <Flex
            justify="between"
            align="center"
            css={{
              fontSize: '0',
              height: 'var(--primary-nav-height)',
            }}
          >
            <LogoWrap isTransparent={isTransparentNavigation}>
              <Link
                href="/"
                tracking={{
                  dataTrack: 'primary-nav',
                  dataTrackText: 'logo',
                  dataTrackUrl: '/',
                }}
              >
                <LogoLCV />
              </Link>
            </LogoWrap>
            <Flex
              justify="between"
              align="center"
              gap={{ '@initial': '6', '@md': '7' }}
              css={{ height: '100%' }}
            >
              <MainNav className={toggleMenu ? 'is-nav-open' : ''}>
                {!!OVERLAY_TRANS.shouldMount && (
                  <Overlay
                    className={submenu !== -1 && 'is-subnav-open'}
                    onClick={() => onCloseSubMenu()}
                    css={{
                      '@lg': {
                        pe: OVERLAY_TRANS.stage === 'enter' ? 'all' : 'none',
                        opacity: OVERLAY_TRANS.stage === 'enter' ? '1' : '0',
                      },
                    }}
                  />
                )}
                <MenuList
                  ref={menuToggle}
                  variant="cv"
                  className={submenu !== -1 && 'is-subnav-open'}
                >
                  {props.menuMain.map((o, i) => (
                    <MenuItem
                      key={i}
                      variant="cv"
                      isTransparent={isTransparentNavigation}
                      css={o.showPanel ? { '@lg': { display: 'none' } } : {}}
                    >
                      {o.url ? (
                        <Link
                          href={o.url}
                          target={o.target}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant={
                              isTransparentNavigation
                                ? {
                                    '@initial': 'text-black',
                                    '@lg': 'text-white',
                                  }
                                : 'text-black'
                            }
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: o.label }}
                          />
                        </Link>
                      ) : (
                        <Box
                          key={i}
                          className={`menu-link ${
                            submenu === i && 'is-menu-open'
                          }`}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                          onClick={() => {
                            onClickMenu(i);
                          }}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant={
                              isTransparentNavigation
                                ? {
                                    '@initial': 'text-black',
                                    '@lg': 'text-white',
                                  }
                                : 'text-black'
                            }
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                          >
                            {o.label}
                          </Text>

                          {!!(o.submenu && o.submenu.length > 0) && (
                            <>
                              {!toggleMenu && (
                                <Icon
                                  icon="expand-more"
                                  color={
                                    isTransparentNavigation
                                      ? 'icon-white'
                                      : 'icon-gray'
                                  }
                                  css={{
                                    '@maxlg': {
                                      display: 'none',
                                    },
                                  }}
                                />
                              )}

                              {!!toggleMenu && (
                                <Icon
                                  icon="chevron-right"
                                  color="icon-gray"
                                  css={{
                                    '@lg': {
                                      display: 'none',
                                    },
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      )}

                      {!!o.submenu && o.submenu.length > 0 && (
                        <>
                          {transition((state, stage) => (
                            <>
                              {state === i && (
                                <SubMenu
                                  ref={subMenuToggle}
                                  className={submenu === i && 'is-subnav-open'}
                                  css={{
                                    '@maxlg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateX(0)'
                                          : 'translateX(100%)',
                                      transitionProperty: 'transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'transform',
                                    },
                                    '@lg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      opacity: stage === 'enter' ? '1' : '0',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateY(0)'
                                          : 'translateY(-40px)',
                                      transitionProperty: 'opacity, transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'opacity, transform',
                                    },
                                  }}
                                >
                                  <Container size="desktop-only">
                                    <SubMenuBack
                                      onClick={() => onCloseSubMenu()}
                                    >
                                      <Flex gapX="1" align="center">
                                        <SubMenuBackIcon>
                                          <Icon
                                            icon="chevron-left"
                                            color="icon-red"
                                          />
                                        </SubMenuBackIcon>
                                        <Text
                                          size="xl"
                                          variant="text-red"
                                          css={{
                                            pt: 2,
                                            lineHeight:
                                              'calc(var(--primary-nav-height) - 1px - 2px)',
                                          }}
                                        >
                                          {o.label}
                                        </Text>
                                      </Flex>
                                      <MenuMobileClose
                                        onClick={() =>
                                          setToggleMenu(!toggleMenu)
                                        }
                                      >
                                        <Icon icon="close" color="icon-gray" />
                                      </MenuMobileClose>
                                    </SubMenuBack>
                                    <CarMenuWrap
                                      ref={(ref) => {
                                        refArray.current[i] = ref;
                                      }}
                                    >
                                      <CarContentList size="2">
                                        {o.submenu.map((k, i) => (
                                          <CarContentItem size="2" key={i}>
                                            <CarMenuLayoutLCV {...k} />
                                          </CarContentItem>
                                        ))}
                                      </CarContentList>
                                      <Close onClick={() => onCloseSubMenu()}>
                                        <ButtonIcon
                                          icon="close"
                                          size="md"
                                          variant="button-icon-color-gray-dark"
                                          isTransparent
                                        />
                                      </Close>
                                    </CarMenuWrap>
                                  </Container>
                                </SubMenu>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>

                {!!toggleMenu && (
                  <MenuMobileTop
                    variant="cv"
                    className={submenu !== -1 && 'is-subnav-open'}
                  >
                    <MenuMobileTopInner>
                      <LogoWrap>
                        <Link
                          href="/"
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: 'logo',
                            dataTrackUrl: '/',
                          }}
                        >
                          <LogoCommonLCV />
                        </Link>
                      </LogoWrap>
                      <MenuMobileClose
                        onClick={() => setToggleMenu(!toggleMenu)}
                      >
                        <Icon icon="close" color="icon-gray" />
                      </MenuMobileClose>
                    </MenuMobileTopInner>
                  </MenuMobileTop>
                )}
              </MainNav>

              <Box>
                <Button
                  size="md"
                  variant={
                    isTransparentNavigation ? 'button-glost' : 'button-red'
                  }
                  label={BUTTON_LCV_REGISTER.label}
                  href={BUTTON_LCV_REGISTER.url}
                  tracking={{
                    dataTrack: 'secondary-nav',
                    dataTrackText: 'สนใจรถ',
                    dataTrackUrl: BUTTON_LCV_REGISTER.url,
                  }}
                />
              </Box>

              <Box
                onClick={() => setToggleMenu(!toggleMenu)}
                css={{
                  '@lg': {
                    display: 'none',
                  },
                }}
                test={{
                  dataTest: 'hamburger',
                }}
              >
                <Hamburger isTransparent={isTransparent} />
              </Box>

              {!!props.showMenuPanel && (
                <>
                  <Box
                    onClick={() => setTogglePanel(!togglePanel)}
                    css={{
                      display: 'none',
                      '@lg': {
                        display: 'block',
                      },
                    }}
                    test={{
                      dataTest: 'hamburger',
                    }}
                  >
                    <Hamburger isTransparent={isTransparent} />
                  </Box>

                  {!!PANEL_OVERLAY_TRANS.shouldMount && (
                    <MenuPanelOverlay
                      onClick={() => setTogglePanel(!togglePanel)}
                      className={togglePanel ? 'is-nav-open' : ''}
                      css={{
                        pe:
                          PANEL_OVERLAY_TRANS.stage === 'enter'
                            ? 'all'
                            : 'none',
                        opacity:
                          PANEL_OVERLAY_TRANS.stage === 'enter' ? '1' : '0',
                      }}
                    />
                  )}
                  {!!PANEL_TRANS.shouldMount && (
                    <MenuPanel
                      className={togglePanel ? 'is-nav-open' : ''}
                      css={{
                        pe: PANEL_TRANS.stage === 'enter' ? 'all' : 'none',
                        transform:
                          PANEL_TRANS.stage === 'enter'
                            ? 'translateX(0)'
                            : 'translateX(100%)',
                      }}
                    >
                      <MenuMobileTop variant="lcv">
                        <MenuMobileTopInner>
                          <LogoWrap>
                            <Link
                              href="/"
                              tracking={{
                                dataTrack: 'primary-nav',
                                dataTrackText: 'logo panel',
                                dataTrackUrl: '/',
                              }}
                            >
                              <LogoCommonLCV />
                            </Link>
                          </LogoWrap>
                          <MenuMobileClose
                            onClick={() => setTogglePanel(!togglePanel)}
                          >
                            <Icon icon="close" color="icon-gray-lightest" />
                          </MenuMobileClose>
                        </MenuMobileTopInner>
                      </MenuMobileTop>
                      <MenuList variant="lcv" ref={menuTogglePanel}>
                        {props.menuMain.map((o, i) => (
                          <React.Fragment key={i}>
                            {!!o.showPanel && (
                              <MenuItem variant="lcv">
                                {!!o.url && (
                                  <Link
                                    href={o.url}
                                    target={o.target}
                                    tracking={{
                                      dataTrack: 'primary-nav',
                                      dataTrackText: o.label,
                                      dataTrackUrl: o.url,
                                    }}
                                  >
                                    <Text
                                      size={{
                                        '@initial': 'h5',
                                        '@lg': 'h6',
                                      }}
                                      variant="text-black"
                                      css={{
                                        pt: 2,
                                        fontSize: 18,
                                        '@lg': {
                                          pt: 2,
                                        },
                                      }}
                                    >
                                      {o.label}
                                    </Text>
                                  </Link>
                                )}
                              </MenuItem>
                            )}
                          </React.Fragment>
                        ))}
                      </MenuList>
                    </MenuPanel>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Container>
      </PrimaryNav>
    </Header>
  );
};
View.displayName = 'Header';
export default View;
