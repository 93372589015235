import React from 'react';

import { styled } from '@/stitches.config';

const Hamburger = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: '100%',
  cursor: 'pointer',
  width: '22px',
  '& span': {
    width: '22px',
    height: '2px',
    display: 'block',
    '&:not(:last-child)': {
      marginBottom: '6px',
    },
  },
  variants: {
    isTransparent: {
      true: {
        '& span': {
          backgroundColor: '$white',
        },
      },
      false: {
        '& span': {
          backgroundColor: '$black',
        },
      },
    },
  },
  defaultVariants: {
    isTransparent: false,
  },
});

const View = ({ isTransparent = false }: { isTransparent?: boolean }) => {
  return (
    <Hamburger isTransparent={isTransparent}>
      <span />
      <span />
      <span />
    </Hamburger>
  );
};
View.displayName = 'SharedElementHamburger';
export default View;
